import { useSimulator } from '../contexts/SimulatorContext';
import { ToggleButtonGroup, ToggleButton, TableBody, TableCell, TableContainer, Paper, TableRow, Table, FormHelperText, FormControl, MenuItem, Select, Typography } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useState } from 'react';
import TypeFrais from './TypeFrais';

import currency from 'currency.js';

import './PaySlip.scss';

export default function InformationsFrais({ tjm }) {
    const { frais, setFrais, alignment, setAlignment, result, inputs, updateInput, errorsSimulator, setErrorsSimulator } = useSimulator();

    useEffect(() => {
        setErrorsSimulator({});
    }, [tjm])

    // Somme des frais pro variables.
    useEffect(() => {
        if (0 < frais.length) {
            let somme = 0;
            frais.forEach(v => {
                somme += parseInt(v.valeur, 10);
            });

            updateInput('b13', somme);
        }
    }, [frais])

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>SMIC 2021</TableCell>
                        <TableCell align="right">{currency(11.65, window.currency_opts).format()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>PMSS</TableCell>
                        <TableCell align="right">{currency(3864, window.currency_opts).format()}</TableCell>
                    </TableRow>
                    <TableRow className='editableRow'>
                        {!tjm &&
                            <>
                                <TableCell>Taux journalier</TableCell>
                                <TableCell align='right'>
                                    <OutlinedInput
                                        name='b6'
                                        size='small'
                                        value={inputs.b6 || ''}
                                        inputProps={{ type: 'number', min: 1, max: 9999 }}
                                        sx={{ width: 200, verticalAlign: 'middle', fontSize: 14 }}
                                        startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                        onChange={e => updateInput('b6', e.target.value || 0)}
                                        error={!!errorsSimulator.b6}
                                    />
                                    {errorsSimulator.b6 && <FormHelperText>{errorsSimulator.b6}</FormHelperText>}
                                </TableCell>
                            </>
                        }
                    </TableRow>
                    <TableRow className='editableRow'>
                        <TableCell>Nb de jours</TableCell>
                        <TableCell align='right'>
                            <OutlinedInput
                                name='b7'
                                size='small'
                                value={inputs.b7 || ''}
                                inputProps={{ type: 'number', min: 1 }}
                                sx={{ width: 200, verticalAlign: 'middle', fontSize: 14 }}
                                startAdornment={<InputAdornment position="start">J</InputAdornment>}
                                onChange={e => updateInput('b7', e.target.value || 0)}
                                error={!!errorsSimulator.b7}
                            />
                            {errorsSimulator.b7 && <FormHelperText>{errorsSimulator.b7}</FormHelperText>}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        {tjm ?
                            <>
                                <TableCell>Net à Payer</TableCell>
                                <TableCell align="right">
                                    <OutlinedInput
                                        name='b8'
                                        size='small'
                                        value={inputs.b8 || ''}
                                        inputProps={{ type: 'number', min: 1 }}
                                        sx={{ width: 200, verticalAlign: 'middle', fontSize: 14 }}
                                        startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                        onChange={e => updateInput('b8', e.target.value || 0)}
                                        error={!!errorsSimulator.b8}
                                    />
                                </TableCell>
                            </>
                            :
                            <>
                                <TableCell>Total CA</TableCell>
                                <TableCell align="right">
                                    {currency(inputs.b6 * inputs.b7 || 0, window.currency_opts).format()}
                                </TableCell>
                            </>
                        }
                    </TableRow>
                    <TableRow className='editableRow'>
                        <TableCell>Heures supplémentaires</TableCell>
                        <TableCell align='right'>
                            <OutlinedInput
                                size='small'
                                inputProps={{ type: 'number', min: 0 }}
                                value={inputs.g3 || ''}
                                sx={{ width: 200, verticalAlign: 'middle', fontSize: 14 }}
                                onChange={e => updateInput('g3', e.target.value || 0)}
                                error={!!errorsSimulator.g3}
                            />
                            {errorsSimulator.g3 && <FormHelperText>{errorsSimulator.g3}</FormHelperText>}
                        </TableCell>
                    </TableRow>

                    {/* Frais de Gestion. */}
                    <TableRow className='editableRow'>
                        <TableCell>Frais de gestion</TableCell>
                        <TableCell align='right'>
                            <OutlinedInput
                                size='small'
                                inputProps={{ type: 'number', min: 0 }}
                                value={(inputs.b10 * 100).toFixed(2) || 0}
                                sx={{ width: 200, verticalAlign: 'middle', fontSize: 14 }}
                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                onChange={e => updateInput('b10', e.target.value / 100 || 0)}
                                error={!!errorsSimulator.b10}
                            />
                            {errorsSimulator.b10 && <FormHelperText>{errorsSimulator.b10}</FormHelperText>}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">
                            <b>Total frais de gestion</b>
                        </TableCell>
                        <TableCell align="right">
                            {currency((inputs.b6 * inputs.b7) * inputs.b10 || 0, window.currency_opts).format()}
                        </TableCell>
                    </TableRow>

                    {/* Faire un Toggle Button entre les frais professionnel fixes et variables. */}
                    <TableRow>
                        <TableCell colSpan={2} align='center'>
                            <ToggleButtonGroup
                                color="warning"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="fraisProFixes">Frais pro fixes</ToggleButton>
                                <ToggleButton value="fraisProDetails">Frais pro détaillés</ToggleButton>
                            </ToggleButtonGroup>
                        </TableCell>
                    </TableRow>
                    <TableRow className='editableRow'>
                        <TableCell align='right'><b>Total frais</b></TableCell>
                        <TableCell align='right'>
                            <OutlinedInput
                                name='b13'
                                size='small'
                                inputProps={{ type: 'number', min: 0 }}
                                value={inputs.b13 || ''}
                                sx={{ width: 200, verticalAlign: 'middle', fontSize: 14 }}
                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                onChange={e => updateInput('b13', e.target.value || 0)}
                                error={!!errorsSimulator.b13}
                            />
                            {errorsSimulator.b13 && <FormHelperText>{errorsSimulator.b13}</FormHelperText>}
                        </TableCell>
                    </TableRow>

                    {/* Choix entre les frais pro fixe ou variés. */}
                    {alignment === 'fraisProDetails' ?
                        <>
                            <TypeFrais frais={frais} setFrais={setFrais} vars={result ? result.vars : null} />
                        </>
                        : <></>
                    }
                    <TableRow className='editableRow'>
                        <TableCell>Mutuelle</TableCell>
                        <TableCell align='right'>
                            <FormControl size="small" sx={{ width: 200, verticalAlign: 'middle', fontSize: 14 }}>
                                <Select
                                    labelId="mutuelle-select-label"
                                    id="mutuelle-select"
                                    value={inputs.c26 || 0}
                                    onChange={e => updateInput('c26', e.target.value || 0)}
                                    style={{ textAlign: 'left', fontSize: 14 }}
                                >
                                    <MenuItem value={1}>Isolé</MenuItem>
                                    <MenuItem value={2}>Famille</MenuItem>
                                    <MenuItem value={0}>Pas de mutuelle</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                    </TableRow>
                    <TableRow className='editableRow'>
                        <TableCell>Taux Prévèvement à la source</TableCell>
                        <TableCell align='right'>
                            <OutlinedInput
                                name='h34'
                                size='small'
                                inputProps={{ type: 'number', min: 0 }}
                                value={inputs.h34 || ''}
                                sx={{ width: 200, verticalAlign: 'middle', fontSize: 14 }}
                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                onChange={e => updateInput('h34', e.target.value || 0)}
                                error={!!errorsSimulator.h34}
                            />
                            {errorsSimulator.h34 && <FormHelperText>{errorsSimulator.h34}</FormHelperText>}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
